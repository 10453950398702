body {
  color: #fff;
  background-color: #0a1021;
  font-family: "Chakra Petch", sans-serif;
}

button,
label,
span,
p {
  font-family: "Chakra Petch", sans-serif !important;
}

input,
input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input:-moz-placeholder,
:where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  > .ant-input-affix-wrapper:not(:last-child)
  .ant-input,
:where(.css-dev-only-do-not-override-12upa3x).ant-input {
  font-family: "Chakra Petch", sans-serif !important;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  > .ant-input-affix-wrapper:not(:last-child)
  .ant-input {
  font-family: "Chakra Petch", sans-serif !important;
}

.ant-input-search
  :where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  height: 40px;
  font-family: "Chakra Petch", sans-serif;
  outline: none;
  border: 0;
  font-size: 16px;
}

.ant-input-search
  :where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  .ant-input-affix-wrapper:not(:last-child):focus,
.ant-input-search
  :where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  .ant-input-affix-wrapper:not(:last-child):hover {
  outline: none;
  border: 0;
  box-shadow: none;
}

.ant-input-search
  :where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  .ant-input-affix-wrapper:not(:last-child):focus,
.ant-input-search
  :where(.css-dev-only-do-not-override-12upa3x).ant-input-group
  .ant-input-affix-wrapper:not(:last-child):hover {
  outline: none;
  border: 0;
  height: 40px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary):hover {
  color: gray;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-input-affix-wrapper
  .ant-input-clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  height: 40px;
}

.ant-popconfirm-buttons {
  display: flex;
  justify-content: flex-end;
}

.ant-popconfirm-buttons button:first-child {
  order: 2;
}

.ant-popconfirm-buttons button:first-child:hover {
  border-color: #d9d9d9;
  color: #000;
}

.ant-popconfirm-buttons button:last-child,
.ant-popconfirm-buttons button:last-child:hover {
  background-color: #f36f2e;
}

.ant-modal-content {
  border-radius: 0 !important;
  background-color: #0a1021 !important;
  opacity: 0.9;
  border: 1px solid #f36f2e !important;
  color: #fff !important;
}

/* .form-vote {
  label {
    color: #fff !important;
  }
} */

:where(.css-dev-only-do-not-override-12upa3x).ant-form-item
  .ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-12upa3x).ant-radio-wrapper
  span.ant-radio
  + * {
  color: #fff !important;
}

button,
input {
  outline: none;
}

.tree-custom > .treeview > svg:first-child {
  display: none;
}

/* .treeview svg {
  display: none;
} */

.treeview::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.treeview {
  overflow-x: auto !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tree-custom > .treeview > div {
  border-left: none !important;
  padding: 0 !important;
}

.tree-bg {
  background: rgb(7, 19, 41);
  background: linear-gradient(
    55deg,
    rgba(7, 19, 41, 1) 20%,
    rgba(42, 82, 152, 0.5004595588235294) 70%
  );
}

.ant-modal-close {
  display: none;
}

.loading-wrapper {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

/* .loading-wrapper div {
  width: 100%;
} */

.loading-wrapper.show-loading {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.go2072408551 {
  max-width: 500px;
}

.css-df17o1 {
  justify-content: center;
}
